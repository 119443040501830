import {
  CUSTOM_MODULE_VERSION_UPDATED,
  CONTENT_JSON_UPDATED,
  SET_ACTIVITY_WIZARD_MODAL_OPEN,
  SET_CUSTOM_MODULES_LOADING,
  SET_CUSTOM_MODULES_ERROR,
  RESET_CUSTOM_MODULES_STATE,
} from '@/store/customModules'
import { getCustomModuleVersion, patchCustomModuleVersion } from '@/services/customModules'

const fetchCustomModuleVersion = (customModuleId, versionId) => async (dispatch) => {
  try {
    dispatch(SET_CUSTOM_MODULES_LOADING(true))

    const customModuleVersion = await getCustomModuleVersion(customModuleId, versionId)
    dispatch(CUSTOM_MODULE_VERSION_UPDATED(customModuleVersion))
  } catch (error) {
    const { message } = error
    dispatch(SET_CUSTOM_MODULES_ERROR(message))
  } finally {
    dispatch(SET_CUSTOM_MODULES_LOADING(false))
  }
}

const updateCustomModuleVersion = (customModuleId, versionId, data) => async (dispatch) => {
  try {
    dispatch(SET_CUSTOM_MODULES_LOADING(true))

    const customModuleVersion = await patchCustomModuleVersion(customModuleId, versionId, data)
    dispatch(CUSTOM_MODULE_VERSION_UPDATED(customModuleVersion))
  } catch (error) {
    const { message } = error
    dispatch(SET_CUSTOM_MODULES_ERROR(message))
  } finally {
    dispatch(SET_CUSTOM_MODULES_LOADING(false))
  }
}

const addPage = (page) => (dispatch, getState) => {
  const { contentJson } = getState().customModules

  const updatedContent = [...(contentJson || []), page]
  dispatch(CONTENT_JSON_UPDATED(updatedContent))
}

const removePage = (pageId) => (dispatch, getState) => {
  const { contentJson } = getState().customModules

  const updatedContent = contentJson.filter((p) => p.id !== pageId)
  dispatch(CONTENT_JSON_UPDATED(updatedContent))
}

const updatePage = (pageId, updates) => (dispatch, getState) => {
  const { contentJson } = getState().customModules

  const updatedContent = contentJson.map((page) => {
    if (page.id === pageId) {
      return { ...page, ...updates }
    }

    return page
  })

  dispatch(CONTENT_JSON_UPDATED(updatedContent))
}

const updatePages = (pages) => (dispatch) => {
  dispatch(CONTENT_JSON_UPDATED(pages))
}

const addPageContent = (pageId, newContent) => (dispatch, getState) => {
  const { contentJson } = getState().customModules

  const updatedContent = contentJson.map((page) => {
    if (page.id === pageId) {
      return {
        ...page,
        content: [...page.content, newContent],
      }
    }

    return page
  })

  dispatch(CONTENT_JSON_UPDATED(updatedContent))
}

const removePageContent = (pageId, contentId) => (dispatch, getState) => {
  const { contentJson } = getState().customModules

  const updatedContent = contentJson.map((page) => {
    if (page.id === pageId) {
      return {
        ...page,
        content: page.content.filter((content) => content.id !== contentId),
      }
    }

    return page
  })

  dispatch(CONTENT_JSON_UPDATED(updatedContent))
}

const updatePageContent = (pageId, contentId, updates) => (dispatch, getState) => {
  const { contentJson } = getState().customModules

  const updatedContent = contentJson.map((page) => {
    if (page.id === pageId) {
      return {
        ...page,
        content: page.content.map((content) => {
          if (content.id === contentId) {
            return { ...content, ...updates }
          }

          return content
        }),
      }
    }

    return page
  })

  dispatch(CONTENT_JSON_UPDATED(updatedContent))
}

const setActivityWizardModalOpen = (isOpen) => (dispatch) => {
  dispatch(SET_ACTIVITY_WIZARD_MODAL_OPEN(isOpen))
}

const resetCustomModulessState = () => (dispatch) => {
  dispatch(RESET_CUSTOM_MODULES_STATE())
}

export {
  fetchCustomModuleVersion,
  updateCustomModuleVersion,
  addPage,
  removePage,
  updatePage,
  updatePages,
  addPageContent,
  removePageContent,
  updatePageContent,
  setActivityWizardModalOpen,
  resetCustomModulessState,
}

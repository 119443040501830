import { useSelector } from 'react-redux'
import { ArrowCircleUpRight24Regular } from '@fluentui/react-icons'
import Button from '@/components/Button'
import { Container } from './styles'

const StackCard = () => {
  const { currentCustomModuleVersion } = useSelector((state) => state.customModules)

  return (
    <Container className="stack-info-card">
      <div className="stack-content">
        <p>(image)</p>

        <Button className="main-button" type="default">
          Stack selected <ArrowCircleUpRight24Regular className="icon" />
        </Button>
      </div>

      <div className="title-container">
        <h5 className="title">Python + Jupyter</h5>
        <p className="description">
          Description lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum
          lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum.
        </p>
      </div>
    </Container>
  )
}

export default StackCard

import { useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { Tag } from 'antd'
import {
  AddCircle24Regular,
  ArrowCircleUpRight24Regular,
  Save24Regular,
  ArrowSquareDown24Regular,
  Edit24Regular,
  Add24Regular,
  HatGraduation24Regular,
} from '@fluentui/react-icons'
import { monitorForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter'
import { getReorderDestinationIndex } from '@atlaskit/pragmatic-drag-and-drop-hitbox/util/get-reorder-destination-index'
import { reorder } from '@atlaskit/pragmatic-drag-and-drop/reorder'
import { extractClosestEdge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge'
import { showToast } from '@/utils/toast'
import { capitalize } from '@/utils/functions'
import { renderModuleImage } from '@/helpers/renderModuleImage'
import Layout1 from '@/layouts/Layout1'
import Button from '@/components/Button'
import ProfilePicture from '@/components/ProfilePicture'
import Section from './components/Section'
import ActivityWizardModal from './components/ActivityWizardModal'
import {
  fetchCustomModuleVersion,
  updateCustomModuleVersion,
  addPage,
  updatePages,
} from '@/store/customModules/actions'
import StackCard from './components/StackCard'
import VersionCard from './components/VersionCard'
import EmptyState from '@/assets/images/custom-modules/custom-module-empty-state.svg?react'
import { SecondaryNavbarContainer, MainContainer, SecondaryContainer } from './styles'

const CustomProjectVersionPage = () => {
  const dispatch = useDispatch()
  const { customModuleId, versionId } = useParams()

  const { isAuthenticated } = useSelector((state) => state.users)
  const {
    currentCustomModuleVersion,
    contentJson,
    isCustomModulesLoading,
    error: customModulesError,
  } = useSelector((state) => state.customModules)

  console.log(currentCustomModuleVersion)
  const handleCustomModuleVersionLoad = async (customModuleId, versionId) => {
    await dispatch(fetchCustomModuleVersion(customModuleId, versionId))
  }

  const handleCustomModuleVersionUpdate = async () => {
    const updatedCustomModuleVersion = {
      content_json: contentJson,
    }
    await dispatch(updateCustomModuleVersion(customModuleId, versionId, updatedCustomModuleVersion))
  }

  const handleAddPage = (predefinedContent) => {
    const emptyPage = {
      id: uuidv4(),
      type: 'page',
      name: `Section ${(contentJson?.length || 0) + 1}`,
      content: predefinedContent || [],
    }

    dispatch(addPage(emptyPage))
  }

  const renderSections = () => {
    return contentJson?.map((page) => {
      return <Section key={page.id} section={page} isDraggable />
    })
  }

  const updateById = (list, id, updatedData) => {
    return list.map((item) => (item.id === id ? { ...item, ...updatedData } : item))
  }

  const reorderSections = useCallback(
    ({ startIndex, finishIndex }) => {
      const updatedSections = reorder({
        list: contentJson,
        startIndex,
        finishIndex,
      })

      dispatch(updatePages(updatedSections))
    },
    [contentJson],
  )

  const reorderContent = useCallback(
    ({ sectionId, startIndex, finishIndex }) => {
      const sourceSectionData = contentJson.find((item) => item.id === sectionId)

      const updatedContent = reorder({
        list: sourceSectionData.content,
        startIndex,
        finishIndex,
      })

      const updatedSection = {
        ...sourceSectionData,
        content: updatedContent,
      }

      dispatch(updatePages(updateById(contentJson, sectionId, updatedSection)))
    },
    [contentJson],
  )

  const moveContent = useCallback(
    ({
      sourceSectionId,
      movedContentIndexInSourceSection,
      destinationSectionId,
      movedContentIndexInDestinationSection,
    }) => {
      const sourceSectionData = contentJson.find((item) => item.id === sourceSectionId)
      const destinationSectionData = contentJson.find((item) => item.id === destinationSectionId)

      const contentToMove = sourceSectionData.content[movedContentIndexInSourceSection]

      const newSourceSectionData = {
        ...sourceSectionData,
        content: sourceSectionData.content.filter((c) => c.id !== contentToMove.id),
      }

      const newDestinationContent = Array.from(destinationSectionData.content)
      const newIndexInDestination = movedContentIndexInDestinationSection ?? 0
      newDestinationContent.splice(newIndexInDestination, 0, contentToMove)

      const newDestinationSectionData = {
        ...destinationSectionData,
        content: newDestinationContent,
      }

      let auxSections = updateById(contentJson, sourceSectionId, newSourceSectionData)
      auxSections = updateById(auxSections, destinationSectionId, newDestinationSectionData)

      dispatch(updatePages(auxSections))
    },
    [contentJson],
  )

  const handleDrop = useCallback(
    ({ source, location }) => {
      const destination = location.current.dropTargets.length
      if (!destination) {
        return
      }

      if (source.data.type === 'SECTION') {
        const sourceSectionId = source.data.id
        const sourceSectionIndex = contentJson.findIndex((item) => item.id === sourceSectionId)

        const destinationSectionRecord = location.current.dropTargets[location.current.dropTargets.length - 1]
        const destinationSectionId = destinationSectionRecord.data.id
        const destinationSectionIndex = contentJson.findIndex((item) => item.id === destinationSectionId)

        if (sourceSectionId === destinationSectionId) {
          return
        }

        const closestEdgeOfTarget = extractClosestEdge(destinationSectionRecord.data)

        const finalDestinationSectionIndex = getReorderDestinationIndex({
          startIndex: sourceSectionIndex,
          indexOfTarget: destinationSectionIndex,
          closestEdgeOfTarget,
          axis: 'vertical',
        })

        reorderSections({
          startIndex: sourceSectionIndex,
          finishIndex: finalDestinationSectionIndex,
        })
      }

      if (source.data.type === 'CONTENT') {
        const sourceContentId = source.data.id
        const [sourceContentRecord, sourceSectionRecord] = location.initial.dropTargets

        const sourceSectionId = sourceSectionRecord.data.id
        const sourceSectionData = contentJson.find((item) => item.id === sourceSectionId)

        const sourceContentIndex = sourceSectionData.content.findIndex((item) => item.id === sourceContentId)

        if (location.current.dropTargets.length === 1) {
          const [destinationSectionRecord] = location.current.dropTargets
          const destinationSectionId = destinationSectionRecord.data.id

          // same section
          if (sourceSectionId === destinationSectionId) {
            const finalDestinationContentIndex = getReorderDestinationIndex({
              startIndex: sourceContentIndex,
              indexOfTarget: sourceSectionData.content.length - 1,
              closestEdgeOfTarget: null,
              axis: 'vertical',
            })

            reorderContent({
              columnId: sourceSectionData.id,
              startIndex: sourceContentIndex,
              finishIndex: finalDestinationContentIndex,
            })
            return
          }

          // other section
          moveContent({
            sourceSectionId,
            movedContentIndexInSourceSection: sourceContentIndex,
            destinationSectionId,
          })
          return
        }

        if (location.current.dropTargets.length === 2) {
          const [destinationContentRecord, destinationSectionRecord] = location.current.dropTargets

          const destinationSectionId = destinationSectionRecord.data.id
          const destinationSectionData = contentJson.find((item) => item.id === destinationSectionId)

          const destinationContentIndex = destinationSectionData.content.findIndex(
            (item) => item.id === destinationContentRecord.data.id,
          )

          const closestEdgeOfTarget = extractClosestEdge(destinationContentRecord.data)

          // same section
          if (sourceSectionId === destinationSectionId) {
            // When you are rendering drop indicators (eg lines) between items in a list,
            // it can be difficult to know what the index the dropped item should go into.
            // The final index will depend on what the closest Edge is.
            // getReorderDestinationIndex can give you the final index for a reordering operation,
            // taking into account which Edge is closest.
            const finalDestinationContentIndex = getReorderDestinationIndex({
              startIndex: sourceContentIndex,
              indexOfTarget: destinationContentIndex,
              closestEdgeOfTarget,
              axis: 'vertical',
            })

            reorderContent({
              sectionId: sourceSectionId,
              startIndex: sourceContentIndex,
              finishIndex: finalDestinationContentIndex,
            })
            return
          }

          const finalDestinationContentIndex =
            closestEdgeOfTarget === 'bottom' ? destinationContentIndex + 1 : destinationContentIndex

          // other section
          moveContent({
            sourceSectionId,
            movedContentIndexInSourceSection: sourceContentIndex,
            destinationSectionId,
            movedContentIndexInDestinationSection: finalDestinationContentIndex,
          })
          return
        }
      }
    },
    [contentJson, reorderContent, moveContent],
  )

  useEffect(() => {
    return monitorForElements({
      onDrop: handleDrop,
    })
  }, [handleDrop])

  useEffect(() => {
    if (contentJson === null) {
      const markdownBlock = {
        id: uuidv4(),
        type: 'markdown_block',
        content: '',
      }

      handleAddPage([markdownBlock])
    }
  }, [contentJson])

  useEffect(() => {
    if (!customModuleId || !versionId) return

    handleCustomModuleVersionLoad(customModuleId, versionId)

    // TODO: is this working or needs another useEffect?
    if (customModulesError) {
      showToast(
        customModulesError === 'Not found.' ? 'Project not found' : 'There was an error loading this project',
        'error',
      )
    }
  }, [customModuleId, versionId]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Layout1
      isLoading={isAuthenticated === null}
      navbar
      secondaryNavbarContent={
        <SecondaryNavbarContainer className="custom-module-secondary-navbar-container">
          <div className="text-container">
            <Edit24Regular className="icon" />
            <span>You’re editing</span>

            <Tag className={`status-tag ${currentCustomModuleVersion?.status}`}>
              <span className="indicator" /> {currentCustomModuleVersion?.status}
            </Tag>
          </div>

          <div className="actions">
            <Button className="button save-button" onClick={handleCustomModuleVersionUpdate}>
              <Save24Regular /> Save
            </Button>

            <Button
              className="button publish-button"
              type="secondary"
              onClick={handleCustomModuleVersionUpdate}
              disabled
            >
              <ArrowSquareDown24Regular />
              Publish
            </Button>
          </div>
        </SecondaryNavbarContainer>
      }
      resizable
      mainContent={
        <MainContainer className="projects-creation-page-main-container" $isEmptyState={!contentJson?.length}>
          {isCustomModulesLoading ? 'LOADING....' : ''}

          {contentJson?.length ? (
            renderSections()
          ) : (
            <div className="empty-state-container">
              <div className="content">
                <EmptyState className="image" />
                <h4 className="title">Unleash your creativity!</h4>
                <p className="text">
                  Add sections, content blocks, and exciting activities with just a few clicks.
                  <br />
                  Click below to get started!
                </p>
              </div>
            </div>
          )}

          <div className="actions">
            <Button
              className="add-button"
              type="default"
              icon={<AddCircle24Regular className="icon" />}
              onClick={() => handleAddPage()}
            >
              Add Section
            </Button>
          </div>

          <ActivityWizardModal />
        </MainContainer>
      }
      secondaryContent={
        <SecondaryContainer className="projects-creation-page-secondary-container">
          <div className="content">
            <div className="module-card">
              <div className="row">
                <div className="title-container">
                  <h3 className="title">{currentCustomModuleVersion?.name}</h3>
                  <p className="description">
                    Description description description description description description description description
                    description description description description description description description description
                    description description description description description description description.
                  </p>
                </div>

                <div className="image-container">{renderModuleImage(currentCustomModuleVersion)}</div>
              </div>

              <div className="row">
                <span className="author-info">
                  <ProfilePicture
                    src={currentCustomModuleVersion?.author?.avatar_url}
                    alt={capitalize(currentCustomModuleVersion?.author?.first_name || 'Anonymous')}
                    size="small"
                    hideBadge
                    disableTooltip
                  />

                  {currentCustomModuleVersion?.author?.first_name
                    ? `${capitalize(currentCustomModuleVersion?.author?.first_name)} ${capitalize(currentCustomModuleVersion?.author?.last_name)}`
                    : 'Anonymous'}
                </span>

                <div className="actions">
                  <Button className="main-button" type="primary" size="large">
                    Start editing <ArrowCircleUpRight24Regular className="icon" />
                  </Button>

                  <Button className="main-button" type="primary" size="large">
                    <Add24Regular className="icon" /> Create new version
                  </Button>

                  <Button className="main-button" type="primary" size="large">
                    <HatGraduation24Regular className="icon" /> Preview as student
                  </Button>
                </div>
              </div>
            </div>

            <div className="row">
              <StackCard />
              <VersionCard />
            </div>
          </div>
        </SecondaryContainer>
      }
    />
  )
}

export default CustomProjectVersionPage

import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import { Switch, Collapse } from 'antd'
import { EyeRegular } from '@fluentui/react-icons'
import Button from '@/components/Button'
import Input from '@/components/Input'
import MarkdownEditor from '@/components/MarkdownEditor'
import { addPageContent, updatePageContent, setActivityWizardModalOpen } from '@/store/customModules/actions'
import { Container } from '../styles'

const InputActivityForm = ({ activityTypeName, icon }) => {
  const dispatch = useDispatch()

  const { isActivityWizardModalOpen } = useSelector((state) => state.customModules)
  const sectionId = isActivityWizardModalOpen?.sectionId
  const activity = isActivityWizardModalOpen?.activity
  const isEditing = !!activity?.id

  const [title, setTitle] = useState('')
  const [content, setContent] = useState('')
  const [correctAnswer, setCorrectAnswer] = useState('')
  const [isCaseSensitive, setIsCaseSensitive] = useState(false)
  const [solution, setSolution] = useState('')
  const [hint, setHint] = useState('')
  const [showSolutions, setShowSolutions] = useState(false)
  const [showHints, setShowHints] = useState(false)

  const handleAddActivity = () => {
    const activityBlock = {
      id: uuidv4(),
      type: 'input',
      title_md: title,
      content_md: content,
      //
      correct_answer: correctAnswer,
      case_sensitive: isCaseSensitive,
      //
      hint_md: hint,
      solution_md: solution,
      show_hints: showHints,
      show_solutions: showSolutions,
    }

    dispatch(addPageContent(sectionId, activityBlock))
    dispatch(setActivityWizardModalOpen(false))
  }

  const handleEditActivity = () => {
    const updatedActivity = {
      ...activity,
      title_md: title,
      content_md: content,
      //
      correct_answer: correctAnswer,
      case_sensitive: isCaseSensitive,
      //
      hint_md: hint,
      solution_md: solution,
      show_hints: showHints,
      show_solutions: showSolutions,
    }

    dispatch(updatePageContent(sectionId, activity?.id, updatedActivity))
    dispatch(setActivityWizardModalOpen(false))
  }

  useEffect(() => {
    if (!activity) return

    setTitle(activity?.title_md)
    setContent(activity?.content_md)
    setCorrectAnswer(activity?.correct_answer)
    setIsCaseSensitive(activity?.case_sensitive)
    setSolution(activity?.solution_md)
    setHint(activity?.hint_md)
    setShowSolutions(activity?.show_solutions)
    setShowHints(activity?.show_hints)
  }, [activity])

  return (
    <Container className="input-activity-form">
      <div className="header">
        <div className="icon-container">{icon}</div>
        <h4 className="title">{isEditing ? 'Editing' : activityTypeName} activity</h4>
      </div>

      <div className="activity-form">
        <Input
          className="activity-title"
          label="Activity title"
          value={title}
          placeholder="Activity title"
          size="large"
          onChange={(evt) => setTitle(evt.target.value)}
        />

        <MarkdownEditor
          label="Activity description and instructions"
          content={content}
          placeholder="Activity description and instructions"
          onChange={setContent}
        />

        <hr />

        <Input
          label="Correct answer"
          value={correctAnswer}
          placeholder="Correct answer"
          onChange={(evt) => setCorrectAnswer(evt.target.value)}
        />

        <div className="option-box">
          <div className="content">
            <div className="info">Is case sensitive?</div>
            <Switch checked={isCaseSensitive} onChange={setIsCaseSensitive} />
          </div>
        </div>

        <hr />

        <Collapse
          className="advanced-options-collapse"
          ghost
          expandIconPosition="end"
          items={[
            {
              key: '1',
              label: 'Show advanced options',
              children: (
                <div className="advanced-options-container">
                  <MarkdownEditor label="Solution" content={solution} placeholder="Solution" onChange={setSolution} />
                  <MarkdownEditor label="Hints" content={hint} placeholder="Hints" onChange={setHint} />

                  <div className="options-container">
                    <div className="option-box">
                      <div className="content">
                        <div className="info">
                          <EyeRegular className="icon" /> Show solution
                        </div>
                        <Switch checked={showSolutions} onChange={setShowSolutions} />
                      </div>
                    </div>

                    <div className="option-box">
                      <div className="content">
                        <div className="info">
                          <EyeRegular className="icon" /> Show hints
                        </div>
                        <Switch checked={showHints} onChange={setShowHints} />
                      </div>
                    </div>
                  </div>
                </div>
              ),
            },
          ]}
        />
      </div>

      <div className="actions">
        <Button type="default" onClick={() => dispatch(setActivityWizardModalOpen(false))}>
          Cancel
        </Button>

        <Button type="primary" onClick={isEditing ? handleEditActivity : handleAddActivity}>
          {isEditing ? 'Update' : 'Create'}
        </Button>
      </div>
    </Container>
  )
}

export default InputActivityForm

import API from '@/services/client'
import { apiErrorHandler } from '@/helpers/errorHandlers'

const getCustomModuleVersion = async (customModuleId, versionId) => {
  const route = `custom-modules/${customModuleId}/versions/${versionId}`
  return API.get(route)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

const patchCustomModuleVersion = async (customModuleId, versionId, data) => {
  const route = `custom-modules/${customModuleId}/versions/${versionId}`
  return API.patch(route, data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

export { getCustomModuleVersion, patchCustomModuleVersion }

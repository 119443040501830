import { useSelector } from 'react-redux'
import { Tag } from 'antd'
import { ArrowCircleUpRight24Regular } from '@fluentui/react-icons'
import Button from '@/components/Button'
import { Container } from './styles'

const VersionCard = () => {
  const { currentCustomModuleVersion } = useSelector((state) => state.customModules)

  return (
    <Container className="version-info-card">
      <div className="version-content">
        <div className="version-item">
          <h5 className="version-code">V{currentCustomModuleVersion?.version_number || '1'}</h5>

          <Tag className={`status-tag ${currentCustomModuleVersion?.status}`}>
            <span className="indicator" /> {currentCustomModuleVersion?.status}
          </Tag>
        </div>

        <Button className="main-button" type="default">
          View versions <ArrowCircleUpRight24Regular className="icon" />
        </Button>
      </div>

      <div className="version-overview">
        <h5 className="title">December 9 2024 12:17pm</h5>
        <p className="description">Created by: Jane Cooper</p>

        <p>Used in 3 titles</p>
      </div>
    </Container>
  )
}

export default VersionCard

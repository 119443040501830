import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 10px 0px;

  &.is-dragging {
    opacity: 0.5;
  }

  > .options-header {
    height: 0px;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    visibility: hidden;
    opacity: 0;
    transition: all 0.2s ease-in-out;
    box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.07);

    .options {
      background-color: ${(props) => props.theme.colors.gray1};
      display: flex;

      .icon-container {
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid ${(props) => props.theme.colors.gray4};
        margin: 0px;
        cursor: pointer;
        user-select: none;

        &:last-child {
          border: none;
          padding-right: 0px;
        }

        > .icon {
          height: 18px;
          width: 18px;
        }

        .drag-button {
          background-color: transparent;

          svg {
            height: 28px;
            width: 28px;
          }
        }

        &:hover {
          background-color: ${(props) => props.theme.colors.gray4};
        }
      }
    }

    .tag {
      background-color: ${(props) => props.theme.colors.gray1};
      border-radius: 2px 2px 0px 0px;
      font-size: 12px;
      line-height: 20px;
      padding: 3px 16px;
      margin: 0px;
    }
  }

  > .section-container {
    background-color: ${(props) => props.theme.colors.gray2};
    display: flex;
    flex-direction: column;
    gap: 0px;
    padding: 16px;
    box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.07);

    .section-header {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;

      .section-name-input {
        background-color: transparent !important;
        border: none;
        font-size: 24px;
        font-weight: 600;
        line-height: 28px;
      }
    }

    .actions {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 12px;
      padding: 0px;
      transition: all 0.3s;
      margin-top: 24px;

      .add-button {
        height: auto;
        width: 100%;
        background-color: ${(props) => props.theme.colors.gray4};
        border-color: ${(props) => props.theme.colors.gray4};
        border-radius: 6px !important;
        padding: 12px 16px;
      }
    }
  }

  &:focus,
  &:hover,
  &:active {
    > .options-header {
      height: 26px;
      opacity: 1;
      visibility: visible;
    }
  }
`

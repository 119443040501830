import styled, { css } from 'styled-components'
import { Input as AntdInput } from 'antd'

export const Container = styled.div`
  .label {
    height: 0px;
    color: ${(props) => props.theme.colors.gray7};
    font-size: 12px;
    font-weight: 500;
    visibility: hidden;
    opacity: 0;
    margin: 0px;
    transition: all 0.3s;
    user-select: none;
  }

  ${(props) =>
    props.$hasContent &&
    css`
      .label {
        height: 22px;
        visibility: visible;
        opacity: 1;
      }
    `}
`

export const Input = styled(AntdInput)`
  &.dw-input {
  }
`

import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  padding: 10px 0px;

  &.is-dragging {
    opacity: 0.4;
  }

  > .options-header {
    height: 0px;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    visibility: hidden;
    opacity: 0;
    transition: all 0.2s ease-in-out;
    box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.07);

    .options {
      background-color: ${(props) => props.theme.colors.gray1};
      display: flex;

      .icon-container {
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid ${(props) => props.theme.colors.gray4};
        margin: 0px;
        cursor: pointer;
        user-select: none;

        &:last-child {
          border: none;
          padding-right: 0px;
        }

        > .icon {
          height: 18px;
          width: 18px;
        }

        .drag-button {
          background-color: transparent;

          svg {
            height: 28px;
            width: 28px;
          }
        }

        &:hover {
          background-color: ${(props) => props.theme.colors.gray4};
        }
      }
    }

    .tag {
      background-color: ${(props) => props.theme.colors.geekblue2};
      border-radius: 2px 2px 0px 0px;
      font-size: 12px;
      line-height: 20px;
      padding: 3px 6px;
      margin: 0px;
    }
  }

  > .content-container {
    .mdxeditor {
      background-color: transparent;
    }

    .activity-preview-container {
      background: ${(props) => props.theme.colors.gray1};
      position: relative;
      padding: 20px;
      border-bottom: 1px solid ${(props) => props.theme.colors.gray4};
      box-shadow: inset 0px 2px 0px ${(props) => props.theme.colors.gray7};

      .activity-body {
        display: flex;
        justify-content: space-between;
        gap: 10px;

        .activity-number {
          color: ${(props) => props.theme.colors.gray10};
          height: 30px;
          max-width: 30px;
          width: 100%;
          background-color: ${(props) => props.theme.colors.gray4};
          font-size: 12px;
          font-weight: 500;
          display: flex;
          border-radius: 50%;
          align-items: center;
          justify-content: center;
          margin-top: -2px;
        }

        .title-container {
          flex: 1;

          .title {
            color: ${(props) => props.theme.colors.gray10};
            font-size: 15px;
            font-weight: 700;
            line-height: 22px;
          }
        }
      }

      .activity-content-markdown {
        p {
          color: ${(props) => props.theme.colors.gray7};
          margin: 0px;
        }
      }
    }
  }

  &:focus,
  &:hover,
  &:active {
    > .options-header {
      height: 26px;
      opacity: 1;
      visibility: visible;
    }

    > .content-container {
      border: 1px solid ${(props) => props.theme.colors.geekblue3};

      .mdxeditor {
        border: none;
      }
    }
  }
`

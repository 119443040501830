import { useEffect, useRef } from 'react'
import {
  MDXEditor,
  // toolbar components
  Separator,
  UndoRedo,
  BlockTypeSelect,
  BoldItalicUnderlineToggles,
  CreateLink,
  ListsToggle,
  InsertTable,
  CodeToggle,
  InsertCodeBlock,
  InsertThematicBreak,
  // InsertAdmonition,
  // AdmonitionDirectiveDescriptor,
  // plugins
  toolbarPlugin,
  headingsPlugin,
  listsPlugin,
  quotePlugin,
  thematicBreakPlugin,
  markdownShortcutPlugin,
  linkPlugin,
  linkDialogPlugin,
  tablePlugin,
  codeBlockPlugin,
  codeMirrorPlugin,
  // directivesPlugin,
} from '@mdxeditor/editor'
import InsertMathFormula from './components/InsertMathFormula'
import { Container } from './styles'

import '@mdxeditor/editor/style.css'

const MarkdownEditor = ({
  className = '',
  label = '',
  content = '',
  placeholder = 'Write something...',
  hideBorder = false,
  isToolbarFixed = false,
  readOnly = false,
  onChange,
}) => {
  const editorRef = useRef(null)

  useEffect(() => {
    editorRef.current?.setMarkdown(content)
  }, [content])

  return (
    <Container
      className={`editor-container ${className}`}
      $hasContent={content !== ''}
      $hideBorder={hideBorder}
      $isToolbarFixed={isToolbarFixed}
      $readOnly={readOnly}
    >
      {label && <p className="label">{label}</p>}

      <MDXEditor
        ref={editorRef}
        plugins={[
          toolbarPlugin({
            toolbarClassName: 'toolbar',
            toolbarContents: () => (
              <>
                <UndoRedo />
                <Separator />
                <BlockTypeSelect />
                <BoldItalicUnderlineToggles />
                <CreateLink />
                <Separator />
                <CodeToggle />
                <InsertCodeBlock />
                <ListsToggle />
                <InsertTable />
                <InsertThematicBreak />
                <InsertMathFormula editorRef={editorRef} onChange={onChange} />
                {/* <InsertAdmonition /> */}
              </>
            ),
          }),
          headingsPlugin(),
          listsPlugin(),
          quotePlugin(),
          thematicBreakPlugin(),
          markdownShortcutPlugin(),
          linkPlugin(),
          linkDialogPlugin(),
          tablePlugin(),
          codeBlockPlugin({ defaultCodeBlockLanguage: 'python' }),
          codeMirrorPlugin({
            codeBlockLanguages: {
              python: 'Python',
              sql: 'SQL',
              r: 'R',
              julia: 'Julia',
              sh: 'Shell',
              json: 'JSON',
            },
          }),
          // directivesPlugin({
          //   directiveDescriptors: [AdmonitionDirectiveDescriptor],
          // }),
        ]}
        markdown={content}
        placeholder={placeholder}
        readOnly={readOnly}
        onChange={onChange}
      />
    </Container>
  )
}

export default MarkdownEditor

import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { TextAddT24Regular, TaskListSquareAdd24Regular, Code24Regular } from '@fluentui/react-icons'
import InputActivityForm from './components/InputActivityForm'
import MultipleChoiceActivityForm from './components/MultipleChoiceActivityForm'
import CodeActivityForm from './components/CodeActivityForm'
import { setActivityWizardModalOpen } from '@/store/customModules/actions'
import { Modal } from './styles'

const ActivityWizardModal = () => {
  const dispatch = useDispatch()

  const { isActivityWizardModalOpen } = useSelector((state) => state.customModules)
  const activity = isActivityWizardModalOpen?.activity

  const [activityType, setActivityType] = useState(null)

  const activityTypesList = [
    {
      name: 'Fill the blank',
      type: 'input',
      icon: <TextAddT24Regular className="icon" />,
      description:
        'Consists of a phrase, sentence, or paragraph with a blank space where a student provides the missing word or words.',
    },
    {
      name: 'Multiple choice',
      type: 'multiple_choice',
      icon: <TaskListSquareAdd24Regular className="icon" />,
      description:
        'A question is presented with several possible answers, and the participant selects the most correct response.',
    },
    {
      name: 'Code validated',
      type: 'code',
      icon: <Code24Regular className="icon" />,
      description: 'Coming soon.',
      disabled: true,
    },
  ]

  const renderActivityTypeSelector = () => {
    return (
      <>
        <h3 className="type-selector-title">Select the type of activity you want to add</h3>

        <div className="activity-types-container">
          {activityTypesList.map((activity) => (
            <div
              key={activity.type}
              className={`activity-content ${activity.disabled ? 'is-disabled' : ''}`}
              onClick={() => (activity.disabled ? null : setActivityType(activity.type))}
            >
              {activity.icon}
              <h5 className="activity-name">{activity.name}</h5>
              <p className="activity-description">{activity.description}</p>
            </div>
          ))}
        </div>
      </>
    )
  }

  const renderActivityForm = (type) => {
    const activityData = activityTypesList.find((activity) => activity.type === type)

    switch (type) {
      case 'input':
        return <InputActivityForm activityTypeName={activityData.name} icon={activityData.icon} />
      case 'multiple_choice':
        return <MultipleChoiceActivityForm activityTypeName={activityData.name} icon={activityData.icon} />
      case 'code':
        return <CodeActivityForm activityTypeName={activityData.name} icon={activityData.icon} />
      default:
        return null
    }
  }

  useEffect(() => {
    if (activity) {
      setActivityType(activity?.type)
    }

    return () => setActivityType(null)
  }, [isActivityWizardModalOpen])

  return (
    <Modal
      className="activity-wizard-modal"
      open={!!isActivityWizardModalOpen}
      onCancel={() => dispatch(setActivityWizardModalOpen(false))}
      footer={null}
      hasActivityType={!!activityType}
    >
      <div className="content">{activityType ? renderActivityForm(activityType) : renderActivityTypeSelector()}</div>
    </Modal>
  )
}

export default ActivityWizardModal

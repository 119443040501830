import styled from 'styled-components'
import customProjectInfoBg from '@/assets/images/custom-modules/custom-module-info-bg.png'

export const SecondaryNavbarContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  overflow: hidden;
  padding: 0px 14px;

  > .text-container {
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 0px;

    > .icon {
      height: 20px;
      width: 20px;
    }

    .status-tag {
      background-color: ${(props) => props.theme.colors.gray1};
      color: ${(props) => props.theme.colors.gray9};
      display: flex;
      align-items: center;
      gap: 5px;
      border: 1px solid ${(props) => props.theme.colors.gray4};

      .indicator {
        height: 7px;
        width: 7px;
        background-color: ${(props) => props.theme.colors.gold5};
        border-radius: 50%;
      }
    }
  }

  > .actions {
    display: flex;
    align-items: center;
    gap: 10px;

    > .button {
      font-weight: 400;
      border-radius: 6px !important;
      padding: 4px 10px;

      svg {
        height: 20px;
        width: 20px;
      }

      &.save-button {
        background-color: ${(props) => props.theme.colors.gray3} !important;
      }

      &.publish-button {
        svg {
          transform: rotate(180deg);
        }
      }
    }
  }
`

export const MainContainer = styled.div`
  height: 100%;
  max-width: 1200px;
  width: 100%;
  background-color: ${(props) => props.theme.colors.gray3};
  display: flex;
  flex-direction: column;
  overflow: auto;

  .empty-state-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 40px 0px 10px;

    > .content {
      max-width: 320px;
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 6px;

      .image {
        margin: 20px 0px;
      }

      .title {
        margin: 0px;
      }

      .text {
        color: ${(props) => props.theme.colors.gray8};
        text-align: center;
        margin: 0px;
      }
    }
  }

  > .actions {
    width: 100%;
    background-color: ${(props) => (props.$isEmptyState ? 'transparent' : props.theme.colors.gray1)};
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 24px 12px;

    .add-button {
      height: auto;
      width: ${(props) => (props.$isEmptyState ? '60%' : '100%')};
      border-radius: 6px !important;
      padding: 12px 16px;

      .icon {
        height: 20px;
        width: 20px;
      }
    }
  }
`

export const SecondaryContainer = styled.div`
  height: 100%;
  background-color: ${(props) => props.theme.colors.gray4};
  position: relative;

  > .content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    gap: 20px;
    padding: 0px 60px;

    .module-card {
      height: 320px;
      background-color: ${(props) => props.theme.colors.geekblue6};
      background-image: url(${customProjectInfoBg});
      background-size: cover;
      background-position: center;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 50px;
      padding: 40px;

      > .row {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
      }

      .title-container {
        display: flex;
        flex-direction: column;
        gap: 6px;

        .title {
          color: ${(props) => props.theme.colors.gray1};
          font-weight: 400;
          margin: 0px;
        }

        .description {
          color: ${(props) => props.theme.colors.geekblue1};
          margin: 0px;
        }
      }

      .image-container {
        .module-image {
          min-width: 98px;
          width: 98px;
          height: auto;
          opacity: ${() => (localStorage.getItem('dw-theme') === 'dark' ? 0.7 : 1)};
          image-rendering: auto;
          pointer-events: none;
        }
      }

      .author-info {
        color: ${(props) => props.theme.colors.gray1};
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        display: flex;
        align-items: center;
      }

      .actions {
        display: flex;
        gap: 10px;

        .main-button {
          background-color: ${(props) => props.theme.colors.gray1};
          color: ${(props) => props.theme.colors.gray12};
          border: none;
          border-radius: 10px !important;

          > .icon {
            width: 22px;
            height: 22px;
          }
        }
      }
    }

    > .row {
      display: flex;
      gap: 20px;
    }
  }
`

import styled from 'styled-components'

export const Container = styled.div`
  &.stack-info-card {
    height: 220px;
    width: 50%;
    background-color: ${(props) => props.theme.colors.gray1};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 10px;
    padding: 28px 30px;

    > .stack-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;

      .main-button {
        font-weight: 400;
        border-color: ${(props) => props.theme.colors.gray5};
        border-radius: 6px !important;
        padding: 10px;

        .icon {
          color: ${(props) => props.theme.colors.gray6};
          transition: 0.3s all;
        }

        &:hover {
          .icon {
            color: ${(props) => props.theme.colors.geekblue4};
          }
        }
      }
    }

    .title-container {
      display: flex;
      flex-direction: column;
      gap: 6px;

      .title {
        margin: 0px;
      }

      .description {
        color: ${(props) => props.theme.colors.gray7};
        font-size: 12px;
        line-height: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        margin: 0px;
      }
    }
  }
`

import { Container, Input as SInput } from './styles'

const Input = ({ className, label, ...rest }) => {
  return (
    <Container $hasContent={rest?.value !== ''}>
      {label && <p className="label">{label}</p>}
      <SInput className={`dw-input ${className}`} {...rest} />
    </Container>
  )
}

export default Input
